<template>
    <breadcrumb btnText="新建文章" @btn-click="$router.push('articles/create')" />

    <div class="page-articles">

        <div class="article-table">
            <table class="table">
                <thead>
                    <td width="60">id</td>
                    <td width="160">栏目</td>
                    <td width="180">标题</td>
                    <td width="220">作者</td>
                    <td width="100">访问人数</td>
                    <td>发布时间</td>
                    <td width="140">操作</td>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in articles.data" :key="index">
                        <td>{{ item.id }}</td>
                        <td>{{ item.category.title }}</td>
                        <td>{{ item.title }}</td>
                        <td>{{ item.author }}</td>
                        <td>{{ item.visits }}</td>
                        <td>{{ item.published_at }}</td>
                        <td>
                            <div class="operation">
                                <n-button class="btn" 
                                    type="primary" 
                                    @click="$router.push(`/content/category/${cat_id}/articles/edit/${item.id}`)"
                                >
                                    <edit theme="outline" size="16" fill="#fff"/>
                                </n-button>
                                <n-popconfirm 
                                    position="bottom right"
                                    content="确定永久删除这篇文章吗？"
                                    @confirm="handlerDelete(item.id, index)"
                                >
                                    <n-button class="btn" type="danger">
                                        <delete theme="outline" size="16" fill="#fff"/>
                                    </n-button>
                                </n-popconfirm>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <no-data v-if="!articles.data"></no-data>
            <!-- 翻页 -->
            <pagination v-if="articles.data && articles.data.length > 10" v-model:list="articles"></pagination>
        </div>

    </div>

    <n-notification 
        v-model:show="notification.show"
        :content="notification.content"
    >
        <template v-slot:icon>
            <attention v-if="notification.state === 'error'" theme="outline" size="24" fill="#ff2121"/>
            <check-one v-else theme="outline" size="24" fill="#00c12b"/>
        </template>
    </n-notification>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import NoData from '@/components/layout/NoData.vue';
import Pagination from '@/components/layout/Pagination.vue';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import { Edit, Delete, Attention, CheckOne } from '@icon-park/vue-next';

@Options({
    components: {
        NoData, Edit, Delete, Attention, CheckOne,
        Pagination, Breadcrumb
    },
    data(){
        return{
            articles: {
                data: []
            },
            cat_id: '',
            notification: {
                show: false,
                content: '',
                state: 'error'
            }
        }
    },
    mounted(){
        if(this.$route.params.id)
            this.cat_id = this.$route.params.id;
            this.getArticles(Number(this.$route.params.id));
    },
    methods: {
        getArticles(id: number){
            this.$api.xrArticles(id).then((res: any) => {
                console.log(res)
                this.articles = res.data;
            })
        },
        handlerDelete(id: number, index: number){
            this.$api.xrDestroyArticle(id).then((res: any) => {
                if(res.msg === 'success') {
                    this.articles.data.splice(index, 1);
                    this.notification = {
                        show: true,
                        content: '文章删除成功',
                        state: 'success'
                    }
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        }
    }
})

export default class Articles extends Vue {}
</script>

<style lang="scss" scoped>
.page-articles{
    @extend .flex-column-center;
    border-radius: $radius;
    background-color: $white;
    box-shadow: $shadow-default;
    .page-head{
        @extend .flex-row-between;
        width: 100%;
        padding: 15px;
        h4{
            @extend .flex-column-middle;
            margin: 0;
        }
    }
    .article-table{
        width: 100%;
    }
}
</style>