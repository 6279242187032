
import { Options, Vue } from 'vue-class-component';
import NoData from '@/components/layout/NoData.vue';
import Pagination from '@/components/layout/Pagination.vue';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import { Edit, Delete, Attention, CheckOne } from '@icon-park/vue-next';

@Options({
    components: {
        NoData, Edit, Delete, Attention, CheckOne,
        Pagination, Breadcrumb
    },
    data(){
        return{
            articles: {
                data: []
            },
            cat_id: '',
            notification: {
                show: false,
                content: '',
                state: 'error'
            }
        }
    },
    mounted(){
        if(this.$route.params.id)
            this.cat_id = this.$route.params.id;
            this.getArticles(Number(this.$route.params.id));
    },
    methods: {
        getArticles(id: number){
            this.$api.xrArticles(id).then((res: any) => {
                console.log(res)
                this.articles = res.data;
            })
        },
        handlerDelete(id: number, index: number){
            this.$api.xrDestroyArticle(id).then((res: any) => {
                if(res.msg === 'success') {
                    this.articles.data.splice(index, 1);
                    this.notification = {
                        show: true,
                        content: '文章删除成功',
                        state: 'success'
                    }
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        }
    }
})

export default class Articles extends Vue {}
